<template>
    <div>
    <highcharts class="stock" :constructor-type="'stockChart'" :options="stockOptions"></highcharts>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                stockOptions: {
                    rangeSelector: {
                        selected: 1
                    },
                    title: {
                        text: 'AAPL Stock Price'
                    },
                    series: [{
                        name: 'AAPL',
                        data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
                        pointStart: Date.UTC(2018, 1, 1),
                        pointInterval: 1000 * 3600 * 24,
                        tooltip: {
                            valueDecimals: 2
                        }
                    }]
                }
            }
        }
    }
</script>
<style scoped>
    .stock {
        width: 70%;
        margin: 0 auto
    }
</style>