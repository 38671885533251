var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('highcharts', {
    staticClass: "stock",
    attrs: {
      "constructor-type": 'stockChart',
      "options": _vm.stockOptions
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }